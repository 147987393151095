import { AST, ContentItemEntity, PhotoEntity } from 'mk2/schemas';

export const getFirstParagraphsPlaintext = (contentAST: AST): string => {
    const paragraphs = getAllDescendantsOfAType(contentAST, 'paragraph');
    for (const paragraph of paragraphs) {
        const plainTexts = getAllDescendantsOfAType(paragraph, 'plain_text');
        let returnValue = '';
        for (const pt of plainTexts) {
            returnValue += pt.text;
        }
        returnValue = returnValue.trim().replace(/\s+/g, ' ');
        // returning firt non empty paragraph's text
        if (returnValue) {
            return returnValue;
        }
    }
    return '';
};

export const getAllPlaintext = (contentAST: AST): string => {
    const plainTexts = getAllDescendantsOfAType(contentAST, 'plain_text');
    let returnValue = '';
    for (const pt of plainTexts) {
        returnValue += ' ' + pt.text.trim();
    }
    return returnValue.trim();
};

export const removeParagraphsAndLinesWithoutPlaintext = (ast: AST, returnWhetherCanRemove = false): boolean | AST => {
    const hasElements = 'elements' in ast && !!ast.elements && ast.elements.length > 0;

    let canRemove = false;

    if (ast.type === 'paragraph') {
        const isBlogParagraph = hasElements && ast.elements.filter(
            (item) => !['h2', 'h3', 'h4', 'paragraph_line', 'paragraph_endblanks'].includes(item.type),
        ).length > 0;
        if (!hasElements || (isBlogParagraph && getAllPlaintext(ast) === '')) {
            canRemove = true;
        }
    }
    else if (ast.type === 'paragraph_line') {
        if (!hasElements || getAllPlaintext(ast) === '') {
            canRemove = true;
        }
    }
    else {
        if (hasElements) {
            for (let i = 0; i < ast.elements.length; i++) {
                const child = ast.elements[i];
                if (removeParagraphsAndLinesWithoutPlaintext(child, true)) {
                    ast.elements.splice(i, 1);
                    i -= 1;
                }
            }
        }
    }
    return returnWhetherCanRemove ? canRemove : ast;
};

export const getFirstDescendantOfAType = (node: AST, nodeType: string): AST => {
    if (node && node.type === nodeType) {
        return node;
    } else {
        if (node && node.elements) {
            for (const e of node.elements) {
                const rv = getFirstDescendantOfAType(e, nodeType);
                if (rv) {
                    return rv;
                }
            }
        }
        return null;
    }
};

export const getAllDescendantsOfAType = (node: AST, nodeType: string, maxDepth: number = -1): AST[] => {
    const rv = [];
    if (node && node.type === nodeType) {
        rv.push(node);
    } else {
        if (node && node.elements && maxDepth !== 0) {
            for (const e of node.elements) {
                rv.push(...getAllDescendantsOfAType(e, nodeType, maxDepth - 1));
            }
        }
    }
    return rv;
};

export const getAllDescendantsOfTypes = (node: AST, nodeTypes: string[], maxDepth: number = -1): AST[] => {
    const rv = [];
    if (node && nodeTypes.includes(node.type)) {
        rv.push(node);
    } else {
        if (node && node.elements && maxDepth !== 0) {
            for (const e of node.elements) {
                rv.push(...getAllDescendantsOfTypes(e, nodeTypes, maxDepth - 1));
            }
        }
    }
    return rv;
};

export const getAttributesDict = (node: AST): any => {
    const attributes = {};
    for (const element of node.elements) {
        if (element.type === 'attribute') {
            const attributeName = element.elements.find((e) => e.type === 'attribute_name').text;
            const attributeValue = element.elements.find((e) => e.type === 'attribute_value').text;
            attributes[attributeName] = attributeValue;
        }
    }
    return attributes;
};

export const convertPicMarkdownSyntaxToImageNum = (markdownizedContentWithPicCodes: string, photos: PhotoEntity[]) => {
    const picMatcher = new RegExp(/<<pic(?:.*?)co?de?=\"(.*?)\"(?:.*?)>>/, 'g');
    while (true) {
        const match = picMatcher.exec(markdownizedContentWithPicCodes);
        if (match) {
            const picPart = match[0];
            const codePart = match[1];
            const picOrderNumber = photos.findIndex((v: PhotoEntity, i: number, o: PhotoEntity[]) => {
                return v.code === codePart;
            });
            if (picOrderNumber !== -1) {
                markdownizedContentWithPicCodes = markdownizedContentWithPicCodes.replace(
                    new RegExp(picPart, 'g'),
                    `[image${picOrderNumber + 1}]`,
                );
            }
        } else {
            break;
        }
    }
    return markdownizedContentWithPicCodes;
};

export const decodeHtmlEntities = (input) => {
    try {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.textContent;
    } catch (e) {
        Object.keys(htmlEntities).forEach((key) => {
            const regex = new RegExp(key, 'g');
            input = input.replace(regex, htmlEntities[key]);
        });
        input = input.replace(/&quot;/g, '"');
        input = input.replace(/&amp;/g, '&');
        return input;
    }
};

export const encodeHtmlEntities = (input) => {
    input = input.replace(/&/g, '&amp;');
    input = input.replace(/"/g, '&quot;');
    Object.keys(htmlEntities).forEach((key) => {
        const regex = new RegExp(htmlEntities[key], 'g');
        input = input.replace(regex, key);
    });
    return input;
};

export const removeQuotes = (s: string): string => {
    if (!s) {
        return s;
    } else {
        const last = s.length - 1;
        return (s?.length > 1 && (s[0] === '\"' || s[0] === '\'') && s[0] === s[last]) ? s.slice(1, last) : s;
    }
};

const htmlEntities = {
    '&apos;': '\'',
    '&lt;': '<',
    '&gt;': '>',
    '&nbsp;': '\xa0',
    '&quot;': '"',
    '&#34;':  '"',
};

export const REVIEW_ARTICLES_HASHTAG_PREFIX = 'cr_';

export const crHashToArticleSlug = (hash: string) => {
    const matcher = new RegExp(`^#?${REVIEW_ARTICLES_HASHTAG_PREFIX}([a-z\d][a-z\d_]+)$`, 'g');
    const match = matcher.exec(hash);
    if (match) {
        return match[1].toLowerCase().replace(new RegExp('_', 'g'), '-');
    }
    return null;
};

function _eolCheck(lineData) {
    if (lineData.doRemove) {
        lineData.checkedElements = lineData.checkedElements.filter(e => !lineData.removableElements.includes(e));
    }
    lineData.removableElements = [];
    lineData.doRemove = false;
}

const BLOCKS = ['paragraph', 'unnumbered_list'];
const LINE_DELIMITERS = ['br', 'unnumbered_list_item_lev1', 'unnumbered_list_item_lev2'];

export function removeLines(ast: AST, removeCondition): AST {
    let changed = false;

    if (removeCondition(ast)) {
        return null;
    }

    const lineData = {
        doRemove: false,
        checkedElements: [],
        removableElements: [],
    };

    for (const element of (ast.elements || [])) {
        if (LINE_DELIMITERS.includes(element.type)) {
            _eolCheck(lineData);
        }
        lineData.removableElements.push(element);
        const checkedElement = removeLines(element, removeCondition);

        if (checkedElement) {
            if (ast !== checkedElement) {
                changed = true;
            }
            lineData.checkedElements.push(checkedElement);
        } else {
            lineData.doRemove = true;
            if (!BLOCKS.includes(ast.type)) {
                return null;
            }
        }
    }
    if (BLOCKS.includes(ast.type)) {
        _eolCheck(lineData);
    }
    if (changed) {
        return { ...ast, elements: lineData.checkedElements };
    }
    return ast;
}

export const hasAnyContent = (ast: AST): boolean => {
    if (ast) {
        if (!['document', 'paragraph', 'br', 'unnumbered_list', 'numbered_list'].includes(ast.type)) {
            return true;
        } else if (ast.type === 'plain_text' && ast.text?.trim()) {
            return true;
        } else {
            if (ast.elements) {
                for (const e of ast.elements) {
                    if (hasAnyContent(e)) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};
